.entireContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #E0E4EC;
    height: 15rem;
    width: clamp(20rem, 25vw, 50rem);
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    width: 90%;
}

.line{
    background-color: #094fb723;
    height: 1px;
    margin-top: 1vh;
  }


.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1rem;
}

.printerTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend SemiBold";
    font-size: 1.3rem;
}

.printerHeading {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Regular";
    font-size: 12pt;
}

.printerSubtitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    font-size: 12pt;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    gap: 1.5rem;
}

@media screen and (max-width: 1050px) {
    .entireContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #E0E4EC;
        height: 100%;
        width: clamp(20rem, 25vw, 50rem);
    }
    
}