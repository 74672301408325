.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.titleContainer {
    margin-bottom: 2rem;
    text-align: center;
}

.verificationTitle {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18px, 3rem, 1000px);
    font-weight: 600;
    line-height: clamp(20px, 3.5rem, 200px);
    transition: 500ms;
    opacity: 1;
    margin-bottom: 1.25rem;
    text-align: center;
}
.verificationSubtitle {
    color: black;
    font-family: "Lexend Light";
    text-align: center;
    font-size: 12pt;
    margin-right: 2rem;
    margin-left: 2rem;
}

.paperContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.singleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
.entireContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1vw;
    gap: 2vw;
}
.textbox {
    width: 20vw;
}

.button {
    width: 25vw;
    height: 5vh;
    background-color: #015F8F;
}

.textContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 1050px) {
    .button {
        width: clamp(15rem, 25vw, 100rem);
        height: 2.75rem;
        background-color: #015F8F;
        margin-top: 1rem;
    }
}