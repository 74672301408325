.entireContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #F2F2F2;
    height: 100%;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12vw;
    margin-bottom: 2rem;
    text-align: left;
    gap: 0.5rem;
}

.title {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18px, 3rem, 1000px);
    font-weight: 600;
    line-height: clamp(20px, 3.5rem, 200px);
    transition: 500ms;
    opacity: 1;
}

.subheading {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18px, 2rem, 1000px);
    font-weight: 600;
    line-height: clamp(20px, 3rem, 200px);
    transition: 500ms;
    opacity: 1;
}

.subtitle {
    color: black;
    font-family: "Lexend Light";
    font-size: 12pt;
}

.filterNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
    border: 1.5px solid #E0E4EC;
    background-color: #E0E4EC;
    height: 3rem;
    margin-bottom: 2vw;
    margin-right: 18vw;
    margin-left: 18vw;
}

.filterButton {
    font-family: "Lexend Light";
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    font-size: 14pt;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .requestsNav {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 18vw;
  }

.bodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

@media screen and (max-width: 850px) {
    .entireContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 2rem;
        background-color: #F2F2F2;
        height: 100%;
    }


    .titleContainer {
        display: flex;
        flex-direction: column;
        margin-left: 0vw;
        margin-bottom: 2rem;
        text-align: center;
        gap: 0.5rem;
    }

    .title {
        color: #015F8F;
        font-family: "Lexend SemiBold";
        font-size: clamp(28pt, 8vw, 38pt);
        font-weight: 600;
        line-height: clamp(20px, 3.5rem, 200px);
        transition: 500ms;
        opacity: 1;
    }

    .subheading {
        color: #015F8F;
        font-family: "Lexend SemiBold";
        font-size: clamp(12pt, 4vw, 24pt);
        font-weight: 600;
        line-height: clamp(20px, 3rem, 200px);
        transition: 500ms;
        opacity: 1;
    }

    .subtitle {
        color: black;
        font-family: "Lexend Light";
        font-size: 11pt;
    }

    .filterNav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: clamp(0rem, 3vw, 5rem);
        border: 1.5px solid #E0E4EC;
        background-color: #E0E4EC;
        height: 3rem;
        margin-bottom: 2vw;
        margin-right: clamp(0rem, 4vw, 50rem);
        margin-left: clamp(0rem, 4vw, 50rem);
    }

    .filterButton {
        font-family: "Lexend Light";
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        font-size: 12pt;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }

    .requestsNav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 0vw;
    }

    .bodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

}