.markerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
}

.teamText {
    color: black;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: 'Helvetica Neue';
}

.locationText {
    display: block;
    font-size: 10pt;
    font-family:'Helvetica Neue'; 
    color: black;
    text-align: right;
}
.wrapper{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    height: 100%;
}

.mobile {
    visibility: hidden;
    width: 0vw;
}

.textboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.popup {
    background-color: #F2F2F2;
}

.textbox {
    width: 22.5rem;
}






@media screen and (max-width: 625px) {
    .menu{
        height: 0vw;
        width: 0vw;
        visibility:hidden;
    }

    .maps {
        width: 0vw;
        height: 0vw;
    }
    .mobile {
        visibility: visible;
        width: 100%
    }

}

/* sx={{marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    m:2}} */