.columnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    width: 50%; /* Take up half of the screen */
    padding-left: 12vw; /* Padding for spacing from the left edge */
    gap: 1.5rem;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Center content horizontally */
    width: 50%; /* Take up half of the screen */
    gap: 1.5rem;
}

.textbox {
    width: clamp(25rem, 34vw, 40rem);
    height: 2.75rem;
    background-color: #FFFFFF;
    margin-top: 0.35rem;
}

.button {
    width: 20rem;
    height: 2.75rem;
    background-color: #015F8F;
    margin-top: 1.5rem;
}


.deleteTextbox {
    width: 22rem;
}


.label {
    color: black;
    font-family: "Lexend Light";
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.switch {
    margin-left: 4.5rem;
}

.dividerLine {
    border-bottom: 1.5px solid #E0E4EC;
    visibility: hidden;
}

.deleteButtonText {
    color: #F0F5FF;
    font-family: "Lexend Light";
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    font-size: 10pt;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    text-transform: none;
  }

  .deleteContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: clamp(50px, 7vw, 200px);
    margin-right: 2vw;
    margin-bottom: 1vw;
  }
  

  @media screen and (max-width: 1050px) {
    .columnContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }


    .leftContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Align content to the left */
        width: 100%; /* Take up half of the screen */
        padding-left: 0vw; /* Padding for spacing from the left edge */
        gap: 0.5rem;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 100%; /* Take up half of the screen */
        gap: 0.5rem;
        margin-top: 0.5rem;
    }
    
    .dividerLine {
        margin-left: 12vw;
        margin-right: 12vw;
        margin-bottom: 1.5rem;
        margin-top: 2rem;
        gap: 1rem;
        border-bottom: 1.5px solid #E0E4EC;
        visibility: visible;
    }

    .textbox {
        width: clamp(20rem, 20vw, 40rem);
        height: 2.75rem;
        background-color: #FFFFFF;
        margin-top: 0.35rem;
    }

    .button {
        width: 20rem;
        height: 2.75rem;
        background-color: #015F8F;
        margin-top: 1.5rem;
    }

  }