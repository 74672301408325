.columnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    width: 50%; /* Take up half of the screen */
    padding-left: 12vw; /* Padding for spacing from the left edge */
    gap: 1.5rem;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Center content horizontally */
    width: 50%; /* Take up half of the screen */
    gap: 1.5rem;
}


.textbox {
    width: clamp(25rem, 34vw, 40rem);
    height: 2.75rem;
    background-color: #FFFFFF;
    margin-top: 0.35rem;
}

.label {
    color: black;
    font-family: "Lexend Light";
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.buttonContainer {
    align-self: flex-end;
    margin-right: 10vw;
    margin-top: 6rem;
}

.button {
    width: 20rem;
    height: 2.75rem;
    background-color: #015F8F;
    margin-top: 1.5rem;
}

@media screen and (max-width: 1050px) {
    .columnContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }


    .leftContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Align content to the left */
        width: 100%; /* Take up half of the screen */
        padding-left: 0vw; /* Padding for spacing from the left edge */
        gap: 0.5rem;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 100%; /* Take up half of the screen */
        gap: 0.5rem;
        margin-top: 0.5rem;
    }

    .textbox {
        width: clamp(20rem, 25vw, 40rem);
        height: 2.75rem;
        background-color: #FFFFFF;
        margin-top: 0.35rem;
    }
    
    .label {
        color: black;
        font-family: "Lexend Light";
        line-height: 20px;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }


    .buttonContainer {
        align-self: center;
        margin-right: 0vw;
        margin-top: 0.5rem;
    }

    .button {
        width: 20rem;
        height: 2.75rem;
        background-color: #015F8F;
        margin-top: 1.5rem;
    }
}