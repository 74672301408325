.columnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    width: 50%; /* Take up half of the screen */
    padding-left: 12vw; /* Padding for spacing from the left edge */
    gap: 0.75rem;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Center content vertically */
    align-items: flex-start; /* Center content horizontally */
    width: 50%; /* Take up half of the screen */
    gap: 0.75rem;
}

.textbox {
    width: clamp(25rem, 34vw, 40rem);
    height: 2.75rem;
    background-color: #FFFFFF;
    margin-top: 0.35rem;
}

.serviceSelect {
    width: clamp(25rem, 34vw, 40rem);
    margin-top: 0.35rem;
}

.buttonContainer {
    align-self: flex-end;
    margin-right: 10vw;
    margin-top: 5rem;
}

.button {
    width: 20rem;
    height: 2.75rem;
    background-color: #015F8F;
    margin-top: 1.5rem;
}

.label {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.uploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 25px;
    margin-top: 0.5rem;
}

.uploadText {
    color: #717B8C;
    font-family: "Lexend Light";
    font-weight: 300;
    letter-spacing: 0;
    line-height: 12pt;
    width: 50%;
  }

.submitContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.uploadContainer_mobile {
    display: none;
    gap: 0px;
    margin-top: 0rem;
    visibility: hidden;
}

@media screen and (max-width: 1000px) {
    .columnContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .leftContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Align content to the left */
        width: 100%; /* Take up half of the screen */
        padding-left: 0vw; /* Padding for spacing from the left edge */
        gap: 0.5rem;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 100%; /* Take up half of the screen */
        gap: 0.5rem;
        margin-top: 0.5rem;
    }

    .textbox {
        width: clamp(20rem, 25vw, 40rem);
        height: 2.75rem;
        background-color: #FFFFFF;
        margin-top: 0.35rem;
    }
    .serviceSelect {
        width: clamp(20rem, 25vw, 40rem);
        margin-top: 0.35rem;
    }
    
    
    .label {
        color: black;
        font-family: "Lexend Light";
        line-height: 20px;
        font-size: 10pt;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }


    .buttonContainer {
        align-self: center;
        margin-right: 0vw;
        margin-top: 0.5rem;
    }

    .button {
        width: 20rem;
        height: 2.75rem;
        background-color: #015F8F;
        margin-top: 1.5rem;
    }

    .uploadContainer {
        display: none;
        gap: 0px;
        margin-top: 0rem;
        visibility: hidden;
    }

    .uploadContainer_mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 25px;
        margin-top: 0.5rem;
        visibility: visible;
    }

    .uploadText {
        color: #717B8C;
        font-family: "Lexend Light";
        font-weight: 300;
        letter-spacing: 0;
        line-height: 12pt;
        width: 22%;
      }
    
    .submitContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

}
