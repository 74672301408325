.entireContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #F2F2F2;
    width: 35vw;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
    margin-top: 1rem;
}

.title {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(22pt, 2.25vw, 48pt);
    font-weight: 600;
    line-height: clamp(20px, 3.5rem, 200px);
    transition: 500ms;
    opacity: 1;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.filterContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 1rem;
    margin-bottom: 2rem;
}

.select {
    width: clamp(20rem, 25vw, 50rem);
}

.subheading {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18px, 2rem, 1000px);
    font-weight: 600;
    line-height: clamp(20px, 3rem, 200px);
    transition: 500ms;
    opacity: 1;
}

.subtitle {
    color: black;
    font-family: "Lexend Light";
    font-size: 12pt;
}

.printerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.printerList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 39.5vw;
    overflow-x: hidden;
    overflow-y: scroll;
}

@media screen and (max-width: 850px) {
    .entireContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #F2F2F2;
        height: 100%;
        width: 100vw;
    }
    .filterContainer {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-left: 1rem;
        margin-bottom: 2rem;
    }
    
    .select {
        width: clamp(20rem, 20rem, 50rem);
    }
}
/* 


.menuScreen {
    display: block;
    padding-top: 2vh;
    padding-right: 1.5vw;
    padding-left: 0.5vw;
    margin-top: 0px;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background-color: #F0F5FF;
    height: 85.5vh;
    width: 25vw;
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
}

.searchBar {
    border-radius: 5px;
    background-color: #F0F5FF;
    border: solid 1px black;
    transition-duration: 500ms;
}

.searchBar:hover {
    background-color: #F0F5FF;
    box-shadow: 5px 5px 3.5px #094fb723;
    transition-duration: 500ms;
}

.dropDownContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.menuTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend SemiBold";
    font-size: 24pt;
    font-weight: 100;
    line-height: 2vh;
    width: 45vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.menuSubtitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    font-size: 12pt;
    margin-left: -0.75vw;
}


.dropDown {
    margin-left: -15.5vw;
    margin-top: 1.5vw;
    width: 200px;
}

.dropDownText {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    font-size: 12pt;
}

.printerList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 58.5vh;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 20px;
    padding-bottom: 20px;
}
.printerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4vw;
    width: 30vw;
}

.paperTitle {
    display: flex;
    
} */