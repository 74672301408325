* {
    margin: 0;
    padding: 0;
}
body {
  overflow-x: hidden;
  width: 100%;
}

.StartScreen {  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: 100%;
    width: 100%;
}

.landingScreen {
  width: 100%;
  height: 90vh;
  background-color:#00344E;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15rem;
  padding-top: 10rem;
}


.title {
  color: #F0F5FF;
  font-family: "Lexend Regular";
  font-size: 6rem;
  font-weight: 600;
  letter-spacing: -1.3px;
  line-height: 6rem;
  transition: 500ms;
  opacity: 1;
 }

.subtitleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 3rem;
  gap: 1rem;
}

.subtitle { 
  color: #F0F5FF;
  font-family: "Lexend Regular";
  font-size: 1rem;
  font-weight: 100;
  line-height: 20px;
  width: 32rem;
}

.line{
  background-color: #FFC926;
  height: 60px;
  width: 3px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 3rem;
  gap: 1rem;
}

.tutorialContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start; /* Center items horizontally */
  margin: 10rem auto 0 auto; /* Adjusted top margin */
  width: 100%; /* Adjust to full width for responsiveness */
  max-width: 1200px;
  padding: 0 5%; /* Responsive padding */
}

.tutorialHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tutorialTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  letter-spacing: -0.84px;
  line-height: 60px;
  margin-top: 7vw;
  width: fit-content;
  font-size: 26pt;
}

.tutorialSubtitle {
  color: #094FB7;
  font-family:'Lexend Regular'; 
  font-size: 12pt;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
  width: 648px;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 1;
  gap: 1rem;
}

.serviceBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start ;
  background-color: #E0E4EC;
  height: 11rem;
  margin-top: 2rem;
  border-radius: 0.25rem;
}

.serviceTextContainer {
  width: 15rem;
}

.serviceTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  width: fit-content;
  font-size: 16pt;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.serviceSubtitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Light";
  width: fit-content;
  font-size: 10pt;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.serviceImage {
  height: 8rem;
  width: auto;
  align-self: center;
  margin-right: 1rem;
}

.serviceButton {
  margin-top: 2rem;
  margin-left: 2rem;
}

.serviceButton {
  align-self: flex-end;
  margin-top: 1rem;
  margin-left: 1rem;
}



.ImageTextContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 10rem;
}

.imageContainer {
  height: 30rem;
  width: auto;
  align-self: center;
}

.imageContainer_large {
  height: 25rem;
  width: auto;
  align-self: center;
}

.image {
  height: 20rem;
  width: auto;
  align-self: center;
}

.textContainer {
  text-align: left;
  width: 35%;
}

.imageTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  letter-spacing: -0.84px;
  line-height: 60px;
  width: fit-content;
  font-size: 36pt;
}
.imageSubtitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Light";
  margin-top: 4vw;
  width: fit-content;
  font-size: 12pt;
}

.imageButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2rem;
  margin-top: 4rem;
}
.imageReverse {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}


.missionContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.missionHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10rem;
}
.missionTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  letter-spacing: -0.84px;
  line-height: 60px;
  margin-top: 5vw;
  width: 1000px;
  font-size: 26pt;
}

.missionDescriptionContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  padding: 0px 0px 0px 0px;
  width: fit-content;
}

.missionDescriptionContainer2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  padding: 0px 0px 0px 24px;
  width: fit-content;
  box-shadow: inset 3px 0px 0px #FFC926;
}

.missionDescription {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Light";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 32px;
  margin-top: -1px;
  width: 60vw;
}

.cuttlelogo {
  margin-top: -3vw;
  height: clamp(200px, 18vw, 300px);
  width: clamp(200px, 18vw, 300px);
}

.footerScreen {
  display: flex;
  height: clamp(300px, 20vw, 500px);
  width: 100%;
  background-color: #02142E;  
  margin-top: 5rem; 
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20vw;
  margin-top: 4vw;
}

.footerColumn1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 15vw;
}

.footerRow2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3vw;
  column-gap: 25vw;
}
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contactUs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}
.rowTitleBig {
  color: #F0F5FF;
  font-family: "Lexend SemiBold";
  font-size: 22pt;
  font-weight: 100;
  line-height: 20px;
  width: fit-content;
}

.rowTitle {
  color: #F0F5FF;
  font-family: "Lexend Regular";
  font-size: 16pt;
  font-weight: 100;
  line-height: 20px;
  width: fit-content;
}

.rowDescription {
  color: #F0F5FF;
  font-family: "Lexend Light";
  font-size: 10pt;
  font-weight: 100;
  line-height: 20px;
  margin-top: 1vw;
  width: fit-content;
  transition-duration: 250ms;
}

.rowDescription:hover {
  color: #0B63E5;
  font-family: "Lexend Light";
  font-size: 10pt;
  font-weight: 100;
  line-height: 20px;
  margin-top: 1vw;
  width: fit-content;
  transition-duration: 250ms;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1vw;
  margin-top: 1vw;
}

.footerIcons {
  height: 100px;
  width: 80px;
  size: 5px;
  color: #F0F5FF;
  border: 1px hidden #f0f5ff63;
  border-radius: 6px;
  transition: 250ms;
}


.footerIcons:hover {
  transition: 250ms;
  background-color: #f0f5ff63;
}

@media screen and (max-width: 1250px) {
  
  .tutorialContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start; /* Center items horizontally */
    margin: 30rem auto 0 auto; /* Adjusted top margin */
    width: 100%; /* Adjust to full width for responsiveness */

    padding: 0 5%; /* Responsive padding */
  }
  
.tutorialHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tutorialTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  letter-spacing: -0.84px;
  line-height: 60px;
  margin-top: 7vw;
  width: fit-content;
  font-size: 26pt;
}

.tutorialSubtitle {
  color: #094FB7;
  font-family:'Lexend Regular'; 
  font-size: 12pt;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
  width: 648px;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 2vw;
}

.serviceBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start ;
  background-color: #E0E4EC;
  height: 15rem;
  margin-top: 2rem;
  border-radius: 0.25rem;
}

.serviceTextContainer {
  width: 12rem;
}

.serviceTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  width: fit-content;
  font-size: 16pt;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.serviceSubtitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Light";
  width: fit-content;
  font-size: 10pt;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.serviceImage {
  height: 8rem;
  width: auto;
  align-self: center;
  margin-right: 1rem;
}

.serviceButton {
  margin-top: 2rem;
  margin-left: 2rem;
}

.serviceButton {
  align-self: flex-end;
  margin-top: 1rem;
  margin-left: 1rem;
}

.ImageTextContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 10rem;
  gap: 15vw;
}

.imageContainer {
  height: 20rem;
  width: auto;
  align-self: center;
}

.imageContainer_large {
  height: 18rem;
  width: auto;
  align-self: center;
}

.image {
  height: 20rem;
  width: auto;
  align-self: center;
}

.textContainer {
  text-align: left;
  width: 35%;
}

.imageTitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Regular";
  font-weight: 600;
  letter-spacing: -0.84px;
  line-height: 60px;
  width: fit-content;
  font-size: 36pt;
}
.imageSubtitle {
  color: rgba(6, 28, 61, 1);
  font-family: "Lexend Light";
  margin-top: 4vw;
  width: fit-content;
  font-size: 12pt;
}

.imageButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2rem;
  margin-top: 4rem;
}

  .missionHeadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0vw;
  }
  
  .missionTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Regular";
    font-weight: 600;
    letter-spacing: -0.84px;
    line-height: 60px;
    margin-top: 5vw;
    width: fit-content;
    font-size: 22pt;
  }

  .missionDescription {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 32px;
    margin-top: -1px;
    width: 60vw;
  }

  .cuttlelogo {
    margin-top: -3vw;
    height: clamp(200px, 18vw, 300px);
    width: clamp(200px, 18vw, 300px);
  }
  
  .footerScreen {
    display: flex;
    height: 300px;
    top: 0px; bottom: 0px; right: 0px; left: 0px;
    background-color: #02142E;  
  }
  
  .footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 20vw;
    margin-top: 4vw;
  }
  
  .footerColumn1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 15vw;
  }
  
  .footerRow2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3vw;
    column-gap: 25vw;
  }
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .contactUs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
  }
  .rowTitleBig {
    color: #F0F5FF;
    font-family: "Lexend SemiBold";
    font-size: 22pt;
    font-weight: 100;
    line-height: 20px;
    width: fit-content;
  }
  .rowTitle {
    color: #F0F5FF;
    font-family: "Lexend Regular";
    font-size: 16pt;
    font-weight: 100;
    line-height: 20px;
    width: fit-content;
  }
  
  .rowDescription {
    color: #F0F5FF;
    font-family: "Lexend Light";
    font-size: 10pt;
    font-weight: 100;
    line-height: 20px;
    margin-top: 1vw;
    width: fit-content;
    transition-duration: 250ms;
  }
  
  .rowDescription:hover {
    color: #0B63E5;
    font-family: "Lexend Light";
    font-size: 10pt;
    font-weight: 100;
    line-height: 20px;
    margin-top: 1vw;
    width: fit-content;
    transition-duration: 250ms;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vw;
    margin-top: 1vw;
  }
  
  .footerIcons {
    height: 100px;
    width: 80px;
    size: 5px;
    color: #F0F5FF;
    border: 1px hidden #f0f5ff63;
    border-radius: 6px;
    transition: 250ms;
  }
  
  
  .footerIcons:hover {
    transition: 250ms;
    background-color: #f0f5ff63;
  }

}
@media screen and (max-width: 1150px) {
  .servicesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 5rem;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2vw;
  }

  .serviceBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start ;
    background-color: #E0E4EC;
    height: 11rem;
    margin-top: 2rem;
    border-radius: 0.25rem;
  }

  .serviceTextContainer {
    width: 15rem;
  }

  .serviceTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Regular";
    font-weight: 600;
    width: fit-content;
    font-size: 16pt;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .serviceSubtitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    width: fit-content;
    font-size: 10pt;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .serviceImage {
    height: 8rem;
    width: auto;
    align-self: center;
    margin-right: 1rem;
  }

  .serviceButton {
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .serviceButton {
    align-self: flex-end;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}


@media screen and (max-width: 1050px) {
    
  .StartScreen {  
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: 100vh;
    width: 100%;
    background-color:#00344E;

  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0rem;
    padding-top: 10rem;
    text-align: center;
  }

  .title {
    color: #F0F5FF;
    font-family: "Lexend Regular";
    font-size: clamp(18pt, 15vw, 48pt);
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 6rem;
    transition: 500ms;
    opacity: 1;
  }

  .subtitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    gap: 1rem;
    width: 80vw;
  }

  .subtitle { 
    color: #F0F5FF;
    font-family: "Lexend Regular";
    font-size: 1rem;
    font-weight: 100;
    line-height: 20px;
    width: 32rem;
  }

  .line{
    background-color: #FFC926;
    height: 60px;
    width: 3px;
    visibility: hidden
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-top: 3rem;
    gap: 1rem;
    width: 80vw;
  }


  .ImageTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
  }

  .imageContainer {
    height: clamp(15rem, 50vw, 30rem);
    width: auto;
    align-self: center;
  }

  .imageContainer_large {
    height: clamp(12.5rem, 50vw, 25rem);
    width: auto;
    align-self: center;
  }

  .image {
    height: 20rem;
    width: auto;
    align-self: center;
  }

  .imageReverse {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .textContainer {
    text-align: left;
    width: 80vw;
  }

  .imageTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend SemiBold";
    font-weight: 600;
    letter-spacing: -0.84px;
    line-height: 60px;
    width: fit-content;
    font-size: 36pt;
  }
  .imageSubtitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    margin-top: 4vw;
    width: fit-content;
    font-size: 12pt;
  }

  .imageButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .missionHeadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10rem;
    width: 90%;
  }
  
  
  
  .cuttlelogo {
    margin-top: -3vw;
    height: clamp(200px, 18vw, 300px);
    width: clamp(200px, 18vw, 300px);
  }

  .footerScreen {
    display: flex;
    height: 300px;
    top: 0px; bottom: 0px; right: 0px; left: 0px;
    background-color: #02142E;  
  }
  
  .footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 25vw;
    margin-top: 10vw;
  }
  
  .footerColumn1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 7vw;
  }
  
  .footerRow2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5vw;
    column-gap: 15vw;
  }
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .contactUs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
  }
  .rowTitleBig {
    color: #F0F5FF;
    font-family: "Lexend SemiBold";
    font-size: 18pt;
    font-weight: 100;
    line-height: 20px;
    width: fit-content;
  }
  .rowTitle {
    color: #F0F5FF;
    font-family: "Lexend Regular";
    font-size: 12pt;
    font-weight: 100;
    line-height: 20px;
    width: fit-content;
  }
  
  .rowDescription {
    color: #F0F5FF;
    font-family: "Lexend Light";
    font-size: 10pt;
    font-weight: 100;
    line-height: 20px;
    margin-top: 1vw;
    width: fit-content;
    transition-duration: 250ms;
  }
  
  .rowDescription:hover {
    color: #0B63E5;
    font-family: "Lexend Light";
    font-size: 10pt;
    font-weight: 100;
    line-height: 20px;
    margin-top: 1vw;
    width: fit-content;
    transition-duration: 250ms;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vw;
    margin-top: 1vw;
  }
  
  .footerIcons {
    height: 100px;
    width: 80px;
    size: 5px;
    color: #F0F5FF;
    border: 1px hidden #f0f5ff63;
    border-radius: 6px;
    transition: 250ms;
  }
  
  
  .footerIcons:hover {
    transition: 250ms;
    background-color: #f0f5ff63;
  }
}

@media (max-width: 750px) {
  .missionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .missionTitle {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Regular";
    font-weight: 600;
    letter-spacing: -0.84px;
    line-height: 60px;
    margin-top: 5vw;
    font-size: 26pt;
  }
  
  .missionDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
  }
  
  .missionDescriptionContainer2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
    padding: 0px 0px 0px 36px;
    width: fit-content;
    box-shadow: inset 3px 0px 0px #FFC926;
    text-align: flex-start;
  }
  
  .missionDescription {
    color: rgba(6, 28, 61, 1);
    font-family: "Lexend Light";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 32px;
    margin-top: -1px;
    width: 60vw;
  }

}