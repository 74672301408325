.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 12vw;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.5rem;
    text-align: center;
}

.title {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18px, 3rem, 1000px);
    font-weight: 600;
    line-height: clamp(20px, 3.5rem, 200px);
    transition: 500ms;
    opacity: 1;
}

.subtitle {
    color: black;
    font-family: "Lexend Light";
    font-size: clamp(12px, 1.5rem, 1000px);
    font-weight: 600;
    transition: 500ms;
    opacity: 1;
  }


.description {
    color: black;
    font-family: "Lexend Light";
    font-size: 12pt;
    text-align: center;
    margin-top: 1vh;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.button {
    width: 20rem;
    height: 2.75rem;
    background-color: #015F8F;
    margin-top: 1.5rem;
}
.backButton {
    margin-top: 4rem;
    margin-left: 12vw;
}

@media screen and (max-width: 850px) {
    .title {
        color: #015F8F;
        font-family: "Lexend SemiBold";
        font-size: clamp(18px, 2rem, 1000px);
        font-weight: 600;
        line-height: clamp(20px, 3.5rem, 200px);
        transition: 500ms;
        opacity: 1;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0vw;
    }
    
    .button {
        width: clamp(15rem, 25vw, 100rem);
        height: 2.75rem;
        background-color: #015F8F;
        margin-top: 1.5rem;
    }
    .backButton {
        margin-top: 2rem;
        align-self: center;
    }
}