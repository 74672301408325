.css-1148kf {
    background-color: #E0E4EC !important;
}

.css-1148kf:hover {
    background-color: #E0E4EC !important; 
}

.css-1qlgtir {
    background-color: #E0E4EC !important;
}

.css-1qlgtir:hover {
    background-color: #E0E4EC !important; 
}
