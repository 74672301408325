.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #F2F2F2;
    height: 87.5vh;
    text-align: center;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 5rem;
}
.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.verificationTitle {
    color: #015F8F;
    font-family: "Lexend SemiBold";
    font-size: clamp(18pt, 3rem, 1000pt);
    font-weight: 600;
    line-height: clamp(20px, 3.5rem, 200px);
    transition: 500ms;
    opacity: 1;
    margin-bottom: 1.25rem;
    width: 100%;
}
.verificationSubtitle {
    color: #015F8F;
    font-family: "Lexend Light";
    text-align: center;
    font-size: clamp(10pt, 1rem, 12pt);
    width: 100%;
    margin-bottom: 10vw;
}

.progress2 {
    margin-top: 5rem;
    align-self: center;
    width: clamp(15rem, 27vw, 100rem);
    height: auto;
}

.progress2_mobile {
    width: 0vw;
    visibility: hidden;
}

@media screen and (max-width: 900px) {
    .progress2 {
        margin-top: 0rem;
        visibility: hidden;
    }

    .progress2_mobile {
        align-self: center;
        margin-top: 2rem;
        width: clamp(20rem, 40vw, 100rem);
        height: auto;
        visibility: visible;
    }

}